import { Above } from '@jetshop/ui/Breakpoints';
import { StyledCarrot } from '@jetshop/ui/Select/Components';
import { styled } from 'linaria/react';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { theme } from '../../Theme';

const SliderWrapper = styled('div')`
  width: 100%;
  position: relative;
  max-width: 85rem;
  margin: auto;

  display: block;

  .slick-list {
    overflow: visible;
  }
  .slick-track {
    display: flex;
    ${theme.below.lg} {
      padding-left: 0;
    }
  }

  &.hide-slide-buttons {
    .slick-next,
    .slick-prev {
      display: none !important;
    }
  }
`;

const Arrow = styled('button')`
  border: 0;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  background: transparent;
  align-items: center;
  ${theme.below.lg} {
    height: 40px;
    width: 40px;
  }

  svg {
    width: 25px;
    height: 8px;
    path {
      fill: #4e3525;
    }
  }

  &:hover {
    svg {
      path {
        fill: #c5bbaa;
      }
    }
  }

  &.slick-next {
    background: #f2f0eb;
    border-radius: 50%;
    right: 20px;
    left: auto;
    z-index: 1;
    svg {
      transform: rotate(-90deg);

      ${theme.below.lg} {
        margin-right: 2px;
      }
    }
    ${theme.below.lg} {
      right: 15px;
    }
  }
  &.slick-prev {
    background: #f2f0eb;
    border-radius: 50%;
    left: 20px;
    right: auto;
    z-index: 1;
    svg {
      transform: rotate(90deg);

      ${theme.below.lg} {
        margin-right: 2px;
      }
    }
    ${theme.below.lg} {
      left: 15px;
    }
  }
  &:focus {
    outline: none;
  }
`;

const OverflowSlider = styled('div')`
  display: flex;
  padding-left: 2rem;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  > div {
    width: 44vw;
    flex: none;
  }
`;

const CustomArrow = props => {
  const { className, onClick } = props;
  return (
    <Arrow className={className} onClick={onClick}>
      <StyledCarrot />
    </Arrow>
  );
};
export const ImageGalleryRowSlider = ({ desktopSlides, children }) => {
  const desktopSlidesInt = parseInt(desktopSlides);
  const desktopSlideQuantity = parseInt(
    children?.length > desktopSlidesInt ? desktopSlidesInt : children?.length
  );

  const desktopSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    slidesToShow: desktopSlideQuantity,
    slidesToScroll: 1,
    adaptiveHeight: false,
    swipeToSlide: true,
    draggable: false
  };

  return (
    <SliderWrapper className="image-slider-wrapper">
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <Slider {...desktopSettings}>{children}</Slider>
          ) : (
            <OverflowSlider>{children}</OverflowSlider>
          )
        }
      </Above>
    </SliderWrapper>
  );
};
