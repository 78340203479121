import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { ReactComponent as ArrowLeft } from '../../../svg/AngleLeft.svg';
import { ReactComponent as ArrowRight } from '../../../svg/AngleRight.svg';

const Arrow = styled('button')`
  border: 0;
  height: 40px;
  width: 40px;
  background: ${props => props.backgroundcolor};
  border: none;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${props => props.radius}px;

  svg {
    width: 28px;
    height: 28px;
    color: ${props => props.foregroundcolor};
  }

  &:hover {
    opacity: 0.8;
  }

  &.TOP {
    &.slick-arrow {
      top: 0;
      bottom: auto;
    }
  }

  &.MIDDLE {
    &.slick-arrow {
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
    }
  }

  &.BOTTOM {
    &.slick-arrow {
      top: auto;
      bottom: 0;
    }
  }

  &.slick-next {
    left: auto;
    right: 0;
  }

  &.slick-prev {
    left: 0;
    right: auto;
  }

  &.side-by-side {
    &.LEFT {
      &.slick-next {
        left: 40px;
        right: auto;
      }

      &.slick-prev {
        left: 0;
        right: auto;
      }
    }

    &.CENTER {
      &.slick-next {
        left: 50%;
        right: auto;
      }

      &.slick-prev {
        left: 50%;
        right: auto;
        transform: translateX(-100%);
      }
    }

    &.RIGHT {
      &.slick-next {
        left: auto;
        right: 0;
      }

      &.slick-prev {
        left: auto;
        right: 40px;
      }
    }
  }

  &.slick-next,
  &.slick-prev {
    z-index: 1;
  }
  &:focus {
    outline: none;
  }
`;

export const ImageGallerySlideButton = props => {
  const {
    className,
    onClick,
    backgroundColor,
    foregroundColor,
    radius,
    horizontalPosition,
    verticalPosition,
    sideBySide = { value: true }
  } = props;

  return (
    <Arrow
      backgroundcolor={backgroundColor?.value}
      foregroundcolor={foregroundColor?.value}
      radius={radius?.value}
      className={cx(
        className,
        sideBySide?.value && 'side-by-side',
        sideBySide?.value && horizontalPosition?.value,
        verticalPosition?.value
      )}
      onClick={onClick}
    >
      {className.includes('slick-next') && <ArrowRight />}
      {className.includes('slick-prev') && <ArrowLeft />}
    </Arrow>
  );
};
