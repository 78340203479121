import { Spacer } from './Spacer';
import { BoxRow, TextBoxItem, ImageBoxItem } from './BoxRow';
import { Hero } from './Hero';

import { ImageBoxRow, ImageBoxRowItem } from './ImageBoxRow';
// import { ImageGalleryRow, ImageGalleryRowItem } from './ImageGalleryRow';
import { VideoHero } from './VideoHero';
import {
  ImageGalleryRow,
  ImageGalleryRowItem
} from './ImageGalleryRow/ImageGalleryRow';
import { ImageGallerySlideButton } from './ImageGalleryRow/ImageGallerySlideButton';

//Anders komponenter, kommenterar ut så länge
// import {
//   ImageGalleryRow,
//   ImageGalleryRowItem
// } from './ImageGalleryRow/ImageGalleryRow';
// import { ImageGallerySlideButton } from './ImageGalleryRow/ImageGallerySlideButton';

export const allContentEditorComponents = {};

// Anders ⬇️
export const andersComponents = {
  SPACER: Spacer

  //Kommenterar ut så länge
  // IMAGEGALLERYROW: ImageGalleryRow,
  // IMAGEGALLERYROWITEM: ImageGalleryRowItem,
  // IMAGEGALLERYROWSLIDERBUTTONS: ImageGallerySlideButton

  // Nedan var redan utkomenterade
  //   IMAGEGALLERYROWCURRENTSLIDESHOWER: ImageGallerySlidePresenter,
  //   IMAGEGALLERYROWPAGECONTROLS: ImageGalleryPageControls
};

//Dimitris
export const startPageEditorComponents = {
  HERO: Hero,
  VIDEOHERO: VideoHero,
  BOXROW: BoxRow,
  TEXTBOX: TextBoxItem,
  IMAGEBOX: ImageBoxItem,
  SPACER: Spacer,
  IMAGEBOXROW: ImageBoxRow,
  IMAGEBOXITEM: ImageBoxRowItem,
  IMAGEGALLERYROW: ImageGalleryRow,
  IMAGEGALLERYROWITEM: ImageGalleryRowItem,
  IMAGEGALLERYROWSLIDERBUTTONS: ImageGallerySlideButton
};
