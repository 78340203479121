import { cx } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import { theme } from '../Theme';
import { ContentPlacer } from './ContentPlacer';

const ImageHeroContentWrapper = styled('div')`
  color: ${props => props.color};
  h2 {
    font-family: ${theme.fonts.primary};
    font-size: 34px;
    line-height: 44px;
    font-weight: 500;
    ${theme.below.lg} {
      font-size: 26px;
      line-height: 33px;
      font-weight: 500;
    }
  }
  h3 {
    font-family: ${theme.fonts.primary};
    font-size: 18px;
    line-height: 23px;
    font-weight: 400;
    ${theme.below.lg} {
      font-size: 18px;
      line-height: 23px;
      font-weight: 400;
    }
  }
  .text {
    margin-top: 5px;
  }

  &.hero {
    h2 {
      font-family: ${theme.fonts.secondary};
      font-size: 54px;
      line-height: 1.1;
      letter-spacing: 0.02em;
      font-weight: normal;
      ${theme.below.lg} {
        font-size: 24px;
        line-height: 31px;
        font-weight: normal;
      }
    }
    h3 {
      font-family: ${theme.fonts.primary};
      font-size: 28px;
      line-height: 36px;
      font-weight: 300;
    }
    .CTA {
      margin: 40px 0;
    }
  }
`;

const CTA = styled('div')`
  margin: 10px 0;
  font-weight: 300;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-decoration: underline;

  a {
    color: unset;
  }
`;

export const BoxContent = ({
  verticalPosition,
  horizontalPosition,
  color,
  title,
  subTitle,
  text,
  buttonText,
  buttonTheme,
  link,
  forType
}) => {
  return (
    <LinkEvaluator link={link?.value}>
      <ContentPlacer
        verticalPosition={verticalPosition}
        horizontalPosition={horizontalPosition}
      >
        <ImageHeroContentWrapper
          color={color?.value ?? '#fff'}
          className={forType}
        >
          <div className="titles-wrapper">
            {subTitle?.value && <h3 className="subTitle">{subTitle?.value}</h3>}
            {title?.value && (
              <h2
                className="title"
                dangerouslySetInnerHTML={{
                  __html: title?.value
                }}
              />
            )}
          </div>
          {text?.value && (
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: text?.value
              }}
            />
          )}
          {buttonText?.value && (
            <CTA className={cx(buttonTheme?.value, horizontalPosition, 'CTA')}>
              <span>{buttonText?.value}</span>
            </CTA>
          )}
        </ImageHeroContentWrapper>
      </ContentPlacer>
    </LinkEvaluator>
  );
};
