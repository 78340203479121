import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';

const ContentPlacerWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 20px;

  ${theme.below.lg} {
    padding: 10px;
  }
  h1,
  h2,
  h3 {
    * {
      font-weight: normal !important;
    }
  }

  &.TOP {
    align-items: flex-start;
    margin-top: 2rem;
  }
  &.MIDDLE {
    align-items: center;
  }

  &.BOTTOM {
    align-items: flex-end;
  }

  &.LEFT {
    * {
      text-align: left;
      margin-left: 0;
      margin-right: auto;
    }
    justify-content: flex-start;
  }

  &.CENTER {
    justify-content: center;

    * {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.RIGHT {
    justify-content: flex-end;
    * {
      text-align: right;
      margin-left: auto;
      margin-right: 0;
    }
  }
`;

// Position to relative parent
export const ContentPlacer = ({
  verticalPosition,
  horizontalPosition,
  children
}) => {
  return (
    <ContentPlacerWrapper
      className={cx(
        verticalPosition?.value,
        horizontalPosition?.value,
        'content-placer'
      )}
    >
      {children}
    </ContentPlacerWrapper>
  );
};
