import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import { BoxContent } from './BoxContent';
import { setVariableFromProp, validateNumber } from '../../utils/HelpFunctions';

export const HeroMaxWidth = styled('div')`
  position: relative;
  padding: 0;
  width: 100%;
  max-width: 85rem;
  margin: auto;

  &.fullWidth {
    max-width: 100%;
  }
  &.padding {
    padding: 0 40px;
    ${theme.below.lg} {
      padding: 0 20px;
    }
  }

  .CTA {
    font-size: 24px;
  }

  .titles-wrapper {
    h2.title {
      font-size: 68px;
      * {
        font-weight: normal !important;
      }
    }
  }

  ${theme.below.lg} {
    padding: 0;
    max-width: 100%;

    .titles-wrapper {
      max-width: 80%;
      h2.title {
        font-size: 36px;
        line-height: 1.1;
      }
    }

    br {
      display: none;
    }
  }
`;

const VideoFrameWrapper = styled('div')`
  position: relative;
  padding-bottom: ${props => props.bottompadding}%;
  margin-bottom: 0px;
  width: 100% !important;
  height: auto !important;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const Video = ({ videoUrl, ratio = '16:9', autoplay = true }) => {
  const vUrl = setVariableFromProp(videoUrl);
  const auto = setVariableFromProp(autoplay);
  const videoRatio = setVariableFromProp(ratio);
  const videoID = vUrl?.split('https://vimeo.com/')?.[1];

  if (!videoID) return null;

  const convertBoolToInt = b => {
    return b ? 1 : 0;
  };

  const intFromAutoPlay = convertBoolToInt(auto);
  const playerVideoUrl = `https://player.vimeo.com/video/${videoID}?autoplay=${intFromAutoPlay}&loop=${intFromAutoPlay}&title=0&byline=0&portrait=0&controls=${!auto}&muted=${auto}`;

  const videoRatioToPercent = ratio => {
    const widthAndHeight = ratio?.split(':');
    if (widthAndHeight?.[0] && widthAndHeight?.[1]) {
      const width = validateNumber(widthAndHeight?.[0]);
      const heigth = validateNumber(widthAndHeight?.[1]);
      return (heigth / width) * 100;
    }
    return null;
  };

  const videoProps = {
    height: 'auto',
    width: '100%'
  };

  return (
    <VideoFrameWrapper bottompadding={videoRatioToPercent(videoRatio)}>
      <iframe title={`Video ${videoID}`} {...videoProps} src={playerVideoUrl} />
    </VideoFrameWrapper>
  );
};

export const VideoHero = ({
  fullWidth,
  padding,
  desktopVideo,
  desktopVideoRatio,
  mobileVideo,
  mobileVideoRatio,
  link,
  textColor,
  handwrittenText,
  title,
  buttonText,
  buttonTheme,
  horizontalPosition,
  verticalPosition
}) => {
  const handleDummyButtonClick = () => {};

  return (
    <Above breakpoint="lg">
      {matches => (
        <HeroMaxWidth
          className={cx(
            fullWidth?.value && 'fullWidth',
            padding?.value && 'padding'
          )}
        >
          <Video
            videoUrl={matches ? desktopVideo?.value : mobileVideo?.value}
            ratio={matches ? desktopVideoRatio?.value : mobileVideoRatio?.value}
          />
          <BoxContent
            verticalPosition={verticalPosition}
            horizontalPosition={horizontalPosition}
            color={textColor}
            handwrittenText={handwrittenText}
            title={title}
            link={link}
            buttonTheme={buttonTheme}
            buttonText={buttonText}
            handleDummyButtonClick={handleDummyButtonClick}
            forType={'hero'}
          />
        </HeroMaxWidth>
      )}
    </Above>
  );
};
