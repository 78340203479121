import Image from '@jetshop/ui/Image';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import 'slick-carousel/slick/slick.css';
import { filterChildrenByKey } from '../../../utils/HelpFunctions';
import { LinkEvaluator } from '../../../utils/LinkEvaluator';
import { theme } from '../../Theme';
import { ImageGalleryRowSlider } from './ImageGalleryRowSlider';
const ImageGalleryRowWrapper = styled('div')`
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 90rem;
  padding: 0 2rem;

  ${theme.below.lg} {
    padding: 0;
  }

  &.full-width {
    max-width: 100%;
    padding: 0;
  }

  .innerWrapper {
    width: 100%;
    display: block;
    position: relative;
  }
`;

const ImageGalleryTitle = styled('h2')`
  font-weight: 600;
  font-size: 26px;
  line-height: 90%;
  letter-spacing: 0.02em;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  ${theme.below.lg} {
    padding: 0 2rem;
    font-size: 22px;
  }
`;

export const ImageGalleryRow = ({
  title,
  imageAspect,
  fullWidth,
  children
}) => {
  const customSlideButtonChildren = filterChildrenByKey(
    children,
    'imageGalleryRowSliderButtons',
    true
  );
  const imageChildren = filterChildrenByKey(
    children,
    'imageGalleryRowItem',
    true
  );

  const modedImageChildren = imageChildren?.map(ic => {
    let childProps = { ...ic.props, imageAspect: imageAspect };
    return { ...ic, props: childProps };
  });

  return (
    <ImageGalleryRowWrapper className={cx(fullWidth?.value && 'full-width')}>
      {title?.value && <ImageGalleryTitle>{title?.value}</ImageGalleryTitle>}
      <div className="innerWrapper">
        <ImageGalleryRowSlider
          desktopSlides={4}
          mobileSlides={2}
          customSlideButtonChild={customSlideButtonChildren?.[0]}
        >
          {modedImageChildren}
        </ImageGalleryRowSlider>
      </div>
    </ImageGalleryRowWrapper>
  );
};

const ImageGalleryRowItemWrapper = styled('div')`
  margin-right: 2px;
  a {
    color: #333;
    text-decoration: none;
  }
`;
const ImageGalleryItemText = styled('p')`
  margin: 10px 0;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
`;

export const ImageGalleryRowItem = ({
  image,
  alt,
  text,
  link,
  imageAspect
}) => {
  const imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2];

  return (
    <ImageGalleryRowItemWrapper link={link}>
      <LinkEvaluator link={link}>
        <Image
          src={image?.value}
          sizes={imageSizes}
          aspect={'300:350'}
          alt={alt?.value}
          cover
        />
        {text?.value && (
          <ImageGalleryItemText>{text?.value}</ImageGalleryItemText>
        )}
      </LinkEvaluator>
    </ImageGalleryRowItemWrapper>
  );
};
