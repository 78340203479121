import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import Image from '@jetshop/ui/Image';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import { BoxContent } from './BoxContent';

const ImageBoxRowWrapper = styled('div')`
  padding: 0;
  width: 100%;
  max-width: 85rem;
  margin: auto;
  display: flex;

  ${theme.below.lg} {
    height: 100%;
    flex-direction: column;
    br {
      display: none;
    }
  }

  &.reverse {
    flex-direction: row-reverse;
  }
  &.fullWidth {
    max-width: 100%;
  }
  &.padding {
    padding: 0 40px;
    ${theme.below.lg} {
      padding: 0 20px;
    }
  }

  .content-placer {
    padding: 0 20px;

    h3 {
      font-weight: 600;
      font-size: 28px;
      line-height: 0.9;
      letter-spacing: 0.01em;
      margin-bottom: 10px;
    }

    ${theme.below.lg} {
      position: relative;
      padding: 30px 20px;
      br {
        display: none;
      }
    }
  }
  .title {
    font-size: 44px;
    line-height: 56px;
    letter-spacing: 0.02em;
    ${theme.below.lg} {
      font-size: 28px;
      line-height: 36px;
      font-weight: 500;
    }
  }
  .text {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.03em;
    ${theme.below.lg} {
      font-size: 14px;
    }
  }

  .image-wrapper {
    --ratio: ${props => props.ratio};
    min-width: var(--ratio);
    ${theme.below.lg} {
      min-width: 100%;
    }
  }
`;

const ImageWrapper = styled('div')`
  width: 100%;
  height: 100%;
  min-width: 50%;
`;
const BoxWrapper = styled('div')`
  position: relative;
  width: 100%;
  background: ${props => props.backgroundcolor};
  ${theme.below.lg} {
    margin: auto;
    margin-top: -60px;
    height: 100%;
    width: calc(100% - 40px);
  }
`;

export const ImageBoxRow = ({
  children,
  padding,
  fullWidth,
  reverse,
  ratio
}) => {
  return (
    <ImageBoxRowWrapper
      className={cx(
        fullWidth?.value && 'fullWidth',
        padding?.value && 'padding',
        reverse?.value && 'reverse'
      )}
      ratio={ratio?.value}
    >
      {children}
    </ImageBoxRowWrapper>
  );
};

export const ImageBoxRowItem = ({
  desktopImage,
  mobileImage,
  desktopImageAspect,
  mobileImageAspect,
  desktopVideoUrl,
  mobileVideoUrl,
  background,
  color,
  title,
  text,
  buttonLink,
  buttonText,
  buttonTheme,
  horizontalPosition,
  verticalPosition
}) => {
  const imageSizes = [1 / 2, 1 / 2, 1 / 2, 1 / 2];

  return (
    <>
      <ImageWrapper className="image-wrapper">
        <Above breakpoint="lg">
          {matches => (
            <Image
              sizes={imageSizes}
              src={matches ? desktopImage?.value : mobileImage?.value}
              aspect={
                matches ? desktopImageAspect?.value : mobileImageAspect?.value
              }
              cover
            />
          )}
        </Above>
      </ImageWrapper>
      <BoxWrapper color={color?.value} backgroundcolor={background?.value}>
        <BoxContent
          verticalPosition={verticalPosition}
          horizontalPosition={horizontalPosition}
          color={color}
          title={title}
          text={text}
          buttonTheme={buttonTheme}
          buttonText={buttonText}
          link={buttonLink}
        />
      </BoxWrapper>
    </>
  );
};
