import React from 'react';
import Image from '@jetshop/ui/Image';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import { BoxContent } from './BoxContent';
import { Helmet } from 'react-helmet-async';

export const HeroMaxWidth = styled('div')`
  position: relative;
  padding: 0;
  width: 100%;
  max-width: 85rem;
  margin: auto;

  &.fullWidth {
    max-width: 100%;
  }
  &.padding {
    padding: 0 40px;
    ${theme.below.lg} {
      padding: 0 20px;
    }
  }

  .CTA {
    font-size: 24px;
  }

  ${theme.below.lg} {
    padding: 0;
    max-width: 100%;

    .titles-wrapper {
      max-width: 80%;
      h2.title {
        font-size: 40px;
        line-height: 1.1;
      }
    }

    br {
      display: none;
    }
  }
`;

export const Hero = ({
  fullWidth,
  padding,
  desktopImage,
  desktopImageRatio,
  mobileImage,
  mobileImageRatio,
  link,
  textColor,
  handwrittenText,
  title,
  buttonText,
  buttonTheme,
  horizontalPosition,
  verticalPosition
}) => {
  const handleDummyButtonClick = () => {};

  return (
    <>
      <Helmet>
        <meta property="og:image" content={desktopImage?.value} />
      </Helmet>
      <Above breakpoint="lg">
        {matches => (
          <HeroMaxWidth
            className={cx(
              fullWidth?.value && 'fullWidth',
              padding?.value && 'padding'
            )}
          >
            <Image
              // TODO - add sizes ??
              src={matches ? desktopImage?.value : mobileImage?.value}
              aspect={
                matches ? desktopImageRatio?.value : mobileImageRatio?.value
              }
              cover
            />
            <BoxContent
              verticalPosition={verticalPosition}
              horizontalPosition={horizontalPosition}
              color={textColor}
              handwrittenText={handwrittenText}
              title={title}
              link={link}
              buttonTheme={buttonTheme}
              buttonText={buttonText}
              handleDummyButtonClick={handleDummyButtonClick}
              forType={'hero'}
            />
          </HeroMaxWidth>
        )}
      </Above>
    </>
  );
};
