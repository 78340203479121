import { Above } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import { theme } from '../Theme';

const BoxRowWrapper = styled('section')`
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  column-gap: ${props => props.gap};
  row-gap: ${props => props.gap};
  background: ${props => props.background};
  padding: ${props => props.padding};

  ${theme.below.lg} {
    flex-direction: column;
    padding: 0;
  }
`;

const TextBoxWrapper = styled('div')`
  width: 100%;
  max-width: ${props => props.maxWidth};
  background: ${props => props.background};
  padding: ${props => props.padding};
  color: ${props => props.color};
  ${theme.below.lg} {
    padding: 20px 20px 0 20px;
    * {
      text-align: left !important;
    }
    h2 {
      font-size: 38px !important;
      line-height: 1.2 !important;
      br {
        display: none;
      }
    }
  }

  h2 {
    font-family: ${theme.fonts.secondary};
  }

  p {
    margin-bottom: 1rem;
    font-size: 15px;
  }
`;

const ImageBoxWrapper = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
  padding: ${props => props.padding};

  ${theme.below.lg} {
    height: 100%;
  }
`;

const ContentWrapper = styled('div')`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  color: ${props => props.color};

  h2 {
    font-weight: 500;
    font-size: 25px;
    line-height: 23px;
    letter-spacing: 0.02em;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
    padding: 3px;
  }

  &.TOP {
    align-self: flex-start;
  }
  &.MIDDLE {
    align-self: center;
  }
  &.BOTTOM {
    align-self: flex-end;
  }
  &.LEFT {
    align-items: flex-start;
    text-align: left;
  }
  &.CENTER {
    align-items: center;
    text-align: center;
  }
  &.RIGHT {
    align-items: flex-end;
    text-align: right;
  }
`;

const CTA = styled('div')`
  margin: 10px 0;
  font-weight: 300;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-decoration: underline;

  a {
    color: unset;
  }
`;

export const TextBoxItem = ({
  textField,
  maxWidth,
  color,
  background,
  padding
}) => {
  return (
    <TextBoxWrapper
      background={background?.value ?? 'transparent'}
      color={color?.value ?? '#333'}
      padding={padding?.value ?? '0'}
      maxWidth={maxWidth?.value ?? '100%'}
    >
      <div dangerouslySetInnerHTML={{ __html: textField?.value }} />
    </TextBoxWrapper>
  );
};

export const ImageBoxItem = ({
  maxWidth,
  desktopImageUrl,
  desktopImageAspect,
  mobileImageUrl,
  mobileImageAspect,
  title,
  text,
  link,
  contentVerticalAlignment,
  contentHorizontalAlignment,
  color,
  buttonTheme,
  buttonText
}) => {
  const vertAlign = contentVerticalAlignment;
  const horAlign = contentHorizontalAlignment;

  return (
    <LinkEvaluator link={link?.value}>
      <Above breakpoint="lg">
        {matches => (
          <ImageBoxWrapper>
            <Image
              quality={100}
              cover={true}
              aspect={
                matches ? desktopImageAspect?.value : mobileImageAspect?.value
              }
              src={matches ? desktopImageUrl?.value : mobileImageUrl?.value}
            />
            <ContentWrapper
              className={cx(vertAlign?.value, horAlign?.value)}
              color={color?.value ?? 'transparent'}
            >
              {title?.value && (
                <h2
                  dangerouslySetInnerHTML={{
                    __html: title?.value
                  }}
                ></h2>
              )}
              {text?.value && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: text?.value
                  }}
                ></p>
              )}
              {buttonText?.value && (
                <CTA className={cx(buttonTheme?.value, horAlign?.value)}>
                  <span>{buttonText?.value}</span>
                </CTA>
              )}
            </ContentWrapper>
          </ImageBoxWrapper>
        )}
      </Above>
    </LinkEvaluator>
  );
};

export const BoxRow = ({ children, gap, background, padding }) => {
  return (
    <BoxRowWrapper
      background={background?.value ?? 'transparent'}
      padding={padding?.value ?? '0'}
      gap={gap?.value ?? '0'}
    >
      {children}
    </BoxRowWrapper>
  );
};
