import { Above } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import React from 'react';
import { validateNumber } from '../../utils/HelpFunctions';

const SpacerWrapper = styled('div')`
  height: ${props => props.height + 'px'};
  width: 100%;
  max-width: 80rem;
  background: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
const DividerWrapper = styled('div')`
  width: ${props => props.width + '%'};
`;

export const Spacer = ({ height, divider = '', width, mobilePercentage }) => {
  const spaceHeight = validateNumber(height?.value);
  const spaceWidth = validateNumber(width?.value);
  const mobPercentage = validateNumber(mobilePercentage?.value);
  const percentageMultiplier = mobPercentage ? mobPercentage / 100 : 0.8;

  return (
    <Above breakpoint="md">
      {matches => (
        <SpacerWrapper
          height={
            matches ? spaceHeight ?? '40' : spaceHeight * percentageMultiplier
          }
        >
          <DividerWrapper
            width={spaceWidth}
            dangerouslySetInnerHTML={{
              __html: divider?.value
            }}
          ></DividerWrapper>
        </SpacerWrapper>
      )}
    </Above>
  );
};
