/**
 * Valuates if its prop from Content Editor Element
 * @param {Any} incomingProp - Property from Content Editor Element or passed inline
 * @return {Any} Prop value
 */
export const setVariableFromProp = incomingProp => {
  // Set prop no matter if its Content Editor prop ||  Element prop
  return (
    incomingProp?.value ??
    (typeof incomingProp === 'object' ? null : incomingProp)
  );
};

/**
 * Filters children by key
 * @param {object} children - Content Editor Element children
 * @param {string} filterText - Text used for checking against key
 * @param {bool} include - Should filtering include or exclude the keys starting with filterText
 * @return {array} Array containing filtered children
 */
export const filterChildrenByKey = (children, filterText, include) => {
  return include
    ? children?.filter(child => child?.key.startsWith(filterText))
    : children?.filter(child => !child?.key.startsWith(filterText));
};

/**
 * Removes tags <x> from string
 * @param {string} textString - Text that will be cleansed from tags
 * @return {string} String without tags
 */
export const removeTags = textString => {
  if (!textString) {
    return '';
  }
  return textString.replace(/(<([^>]+)>)/gi, '');
};

/**
 * Validate if text can be parsed to an Int
 * @param {string} text - Text to validate to number
 * @return {int} correct validated number
 */
export const validateNumber = text => {
  let returnText = parseInt(text);
  if (isNaN(returnText)) {
    returnText = null;
  }
  return returnText;
};

/**
 * Get lorem ipsum text
 * @param {int} length - To shorten the original text use length lower than full text
 * @return {string} lorem ipsum text
 */
export const loremIpsum = (length = null) => {
  const originalText =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

  return length ? originalText.substring(0, length) : originalText;
};

/**
 * Creates object with anchor tag as id
 * @param {string} anchorTag - Anchor tag, used to find element
 * @return {object} object with id
 */
export const createAnchorProps = anchorTag => {
  return anchorTag ? { id: anchorTag } : null;
};
